export const NavMenu = [
    {
      name:"Home",
      link: "/",
      id: 1
    }, 
    {
      link: "/products",
      id: 2,
      name:"Products",
    },
    {
      id: 3,
      link: "/services",
      name:"Sevices",
    }, 
    {
      link: "/contact",
      id: 4,
      name:"Contact",
    },
    {
      link: "/about",
      id: 5,
      name:"About Us",
    }
  ]


  