import './App.css';
import CreateRoutes from './routes/routes';

function App() {
  return (
    <div className="App">
      <CreateRoutes/>
    </div>
  );
}

export default App;
